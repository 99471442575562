<template>
    <div class="summary-video">
        <div class="btn-block">
            <h3>衡中直播课堂管理</h3>
            <p>衡中直播课堂管理</p>
            <div class="btn-bg" @click="videoRouter"></div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        videoRouter() {
            this._fet("/school/videoUrl/getOneBySchoolId", {
                schoolId: this.$store.state.schoolId,
            }).then(res => {
                this.handleRes(res, () => {
                    let schoolUrl = `${res.data.data.videoUrl}admin`
                    let promise = new Promise((reslove) => {
                        reslove(schoolUrl)
                    })
                    promise.then((res) => {
                        const _window = window.open()
                        if (_window) {
                            _window.location.href = res
                        } else {
                            const a = document.createElement('a')
                            a.href = res;
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                        }
                    })
                })
            })
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.iframe {
    height: 100vh;
}

.summary-video {
    padding: 20px;
    line-height: 1;
    box-sizing: border-box;
    background-size: 100% 100%;
    height: calc(100vh - 48px - 60px);
    background-image: url("../../assets/images/summary-video.png");

    .btn-block {
        padding-top: 140px;
        padding-left: 125px;
    }

    h3 {
        margin: 0;
        font-size: 88px;
        margin-bottom: 20px;
        color: rgba(255, 255, 255, 0.08);
    }

    p {
        margin: 0;
        font-size: 48px;
        color: #ffffff;
        padding-left: 40px;
        margin-bottom: 65px;
    }

    .btn-bg {
        width: 260px;
        height: 72px;
        cursor: pointer;
        margin-left: 40px;
        background-size: 100% 100%;
        background-image: url("../../assets/images/summary-video-btn.png");
    }
}
</style>
