// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/summary-video.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/summary-video-btn.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iframe[data-v-1f307610]{height:100vh}.summary-video[data-v-1f307610]{padding:20px;line-height:1;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;background-size:100% 100%;height:-webkit-calc(100vh - 108px);height:-moz-calc(100vh - 108px);height:calc(100vh - 108px);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.summary-video .btn-block[data-v-1f307610]{padding-top:140px;padding-left:125px}.summary-video h3[data-v-1f307610]{margin:0;font-size:88px;margin-bottom:20px;color:hsla(0,0%,100%,.08)}.summary-video p[data-v-1f307610]{margin:0;font-size:48px;color:#fff;padding-left:40px;margin-bottom:65px}.summary-video .btn-bg[data-v-1f307610]{width:260px;height:72px;cursor:pointer;margin-left:40px;background-size:100% 100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
